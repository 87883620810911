import styled from 'styled-components';
import { spacing } from 'helpers/genericStyles';
import { media } from 'helpers/breakpoints';

type TContainer = {
  grid?: boolean;
  cols?: number | number[];
};

export const Container = styled.div<TContainer>`
  ${spacing};
  background: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  padding: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    padding: ${({ theme }) => theme.spacing.M24};
  }

  ${({ theme, grid, cols }) =>
    grid
      ? `
    display: grid;
    grid-template-columns: repeat(${Array.isArray(cols) ? cols[0] : cols}, 1fr);
    column-gap: ${theme.spacing.M24};
    
    ${media.large} {
        grid-template-columns: repeat(${
          Array.isArray(cols) ? cols[1] : cols
        }, 1fr);
    }
  `
      : 'display: flex; flex-direction: column;'}
`;
