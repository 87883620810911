import type { ReactNode } from 'react';

import type { Spacing } from 'helpers/genericStyles';
import { Heading } from 'components/ToolkitV2/Panel/components/Heading';
import { SubHeading } from 'components/ToolkitV2/Panel/components/SubHeading';
import { Span } from 'components/ToolkitV2/Panel/components/Span';
import * as Styled from 'components/ToolkitV2/Panel/Panel.styled';

type TPanel = {
  (props: IPanelProps): JSX.Element;
  Heading: typeof Heading;
  SubHeading: typeof SubHeading;
  /** Anything contained within this will fill the full width of the `Panel` */
  Span: typeof Span;
};

export interface IPanelProps extends Spacing {
  children: ReactNode;
  /** Set to true if you want to apply a grid structure to children */
  grid?: boolean;
  /** The number of columns within the grid, array for media query  */
  cols?: number | number[];
  id?: string;
}

const Panel: TPanel = (props: IPanelProps) => {
  const { children, ...rest } = props;
  return <Styled.Container {...rest}>{children}</Styled.Container>;
};

Panel.Heading = Heading;
Panel.SubHeading = SubHeading;
Panel.Span = Span;

export { Panel };
