import type { ReactNode } from 'react';
import styled from 'styled-components';

import { Spacing, spacing } from 'helpers/genericStyles';

interface IHeadingProps extends Spacing {
  children: ReactNode;
}

const Container = styled.h3`
  ${spacing};
  ${({ theme }) => theme.fontSize.M14};
  grid-column: 1 / 3;
  color: ${({ theme }) => theme.colors.GREY_DARK};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const SubHeading = (props: IHeadingProps) => {
  const { children, ...spacing } = props;
  return <Container {...spacing}>{children}</Container>;
};

export { SubHeading };
