import styled, { css } from 'styled-components';

import { Panel } from 'components/ToolkitV2/Panel/Panel';
import { Checkbox } from 'components/Toolkit/Inputs/Checkbox';

import { media } from 'helpers/breakpoints';
import { spacing } from 'helpers/genericStyles';
import type { Spacing } from 'helpers/genericStyles';
import {
  ORIENTATION,
  OrientationProps,
} from 'components/ToolkitV2/CardV2/Card.typed';

export interface ISizes extends Spacing {
  mWidth?: string;
  mHeight?: string;
  width?: string;
  height?: string;
}
interface ActionSlotStyledProps {
  numOfActions?: number;
  actionWidth?: string;
  actionBarWidth?: string;
}

const PlaceholderElementCSS = css`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.GREY_LIGHT};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(242, 242, 242, 0) 0,
      rgba(242, 242, 242, 0.2) 20%,
      rgba(242, 242, 242, 0.5) 60%,
      rgba(242, 242, 242, 0)
    );
    animation: waveEffect 2s infinite;
    content: '';

    @keyframes waveEffect {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

// My DoneDeal Skeleton Styles

export const BasicContainer = styled.div<ISizes>`
  ${spacing};
  width: ${({ mWidth }) => mWidth};
  height: ${({ mHeight }) => mHeight};
  border-radius: ${({ theme }) => theme.borderRadius.default};

  ${media.large} {
    width: ${({ width, mWidth }) => width ?? mWidth};
    height: ${({ height, mHeight }) => height ?? mHeight};
  }
  ${PlaceholderElementCSS};
`;

export const SubmitButton = styled.div<ISizes>`
  display: none;

  ${media.large} {
    display: block;
    position: absolute;
    top: 24px;
    right: 24px;
    width: 160px;
    height: 40px;
  }
  ${PlaceholderElementCSS};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

export const BasicDetailsPanel = styled(Panel)`
  position: relative;
`;

// Skeleton Card Styles
export const StatusBar = styled.div`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) =>
    `${theme.borderRadius.default} ${theme.borderRadius.default} 0 0`};
`;

export const Header = styled.div`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  display: none;

  ${media.medium} {
    display: block;
  }
`;

export const CardBody = styled.div<{ includeStatusBar: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  ${({ includeStatusBar, theme }) =>
    !includeStatusBar
      ? `border-radius: ${theme.borderRadius.default} ${theme.borderRadius.default} 0 0`
      : ''};
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 120px;
  height: 90px;

  ${media.large} {
    width: 184px;
    height: 138px;
  }
`;

export const Image = styled.div`
  width: 120px;
  height: 90px;
  ${PlaceholderElementCSS}

  ${media.large} {
    width: 184px;
    height: 138px;
  }
`;

export const CardData = styled.div`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.S8};

  ${media.large} {
    padding: ${({ theme }) => theme.spacing.M16};
  }
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 9px;
  left: ${({ theme }) => theme.spacing.S8};

  ${media.large} {
    top: 19px;
    left: ${({ theme }) => theme.spacing.M16};
  }
`;

const FlexContainerCSS = css`
  display: flex;
  align-items: center;
`;

export const MetaDataContainerVariant = styled.ul`
  ${FlexContainerCSS}
`;

export const MetaDataContainerListItem = styled.li`
  ${FlexContainerCSS}
`;

export const PriceContainer = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.S8};
  left: ${({ theme }) => theme.spacing.S8};
  width: calc(100% - (${({ theme }) => theme.spacing.S8} * 2));

  ${media.large} {
    bottom: ${({ theme }) => theme.spacing.M16};
    left: ${({ theme }) => theme.spacing.M16};
    width: calc(100% - (${({ theme }) => theme.spacing.M16} * 2));
  }

  ${BasicContainer} {
    ${media.large} {
      margin-bottom: 1px;
    }
  }
`;

export const ActionBar = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  justify-content: flex-end;
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius.default} ${theme.borderRadius.default}`};
`;

export const SlotContainer = styled.div<ActionSlotStyledProps>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius.default} ${theme.borderRadius.default}`};

  ${media.large} {
    width: ${({ actionBarWidth }) => actionBarWidth};
  }
`;

export const ActionSlot = styled.div<ActionSlotStyledProps>`
  padding: ${({ theme }) =>
    `${theme.spacing.S8} ${theme.spacing.S8} ${theme.spacing.S8} ${theme.spacing.S4}`};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: calc(
    100% / ${({ numOfActions }) => (numOfActions === 0 ? 1 : numOfActions)}
  );
`;

export const Dot = styled.span`
  width: ${({ theme }) => theme.spacing.S4};
  height: ${({ theme }) => theme.spacing.S4};
  background: ${({ theme }) => theme.colors.GREY_LIGHT};
  margin-left: ${({ theme }) => theme.spacing.S8};
  margin-right: ${({ theme }) => theme.spacing.S8};
  border-radius: 50%;
  ${PlaceholderElementCSS}
`;

// Payment History

export const SelectionControlContainer = styled.div`
  display: flex;
  align-items: center;

  ${BasicContainer} {
    ${media.medium} {
      margin-right: ${({ theme }) => theme.spacing.M16};
    }
  }
`;

export const SelectionControlActions = styled.div`
  display: none;

  ${media.medium} {
    display: flex;
    align-items: center;
    border-left: ${({ theme }) => `1px solid ${theme.colors.GREY_LIGHT}`};
    padding-left: ${({ theme }) => theme.spacing.M16};
    > * {
      margin-right: ${({ theme }) => theme.spacing.M16};
    }
  }

  ${BasicContainer} {
    ${media.smallOnly} {
      display: none;
    }
  }
`;

interface SkeletonCardImageProps extends OrientationProps {
  aspectRatioAsAPercentage: string;
  widthHorizontalSmall?: number;
  widthHorizontalMedium?: number;
  widthHorizontalLarge?: number;
  heightHorizontalSmall?: number;
  heightHorizontalMedium?: number;
  heightHorizontalLarge?: number;
}

export const CardImage = styled.div<SkeletonCardImageProps>`
  ${PlaceholderElementCSS}
  padding-top: ${({ aspectRatioAsAPercentage, orientationSmall }) =>
    orientationSmall !== ORIENTATION.HORIZONTAL ? aspectRatioAsAPercentage : 0};
  width: ${({ orientationSmall, widthHorizontalSmall }) =>
    orientationSmall === ORIENTATION.HORIZONTAL && widthHorizontalSmall
      ? `${widthHorizontalSmall}px`
      : '100%'};
  height: ${({ orientationSmall, heightHorizontalSmall }) =>
    orientationSmall !== ORIENTATION.VERTICAL && heightHorizontalSmall
      ? `${heightHorizontalSmall}px`
      : undefined};

  ${media.medium} {
    padding-top: ${({ aspectRatioAsAPercentage, orientationMedium }) =>
      orientationMedium !== ORIENTATION.HORIZONTAL
        ? aspectRatioAsAPercentage
        : 0};
    width: ${({ orientationMedium, widthHorizontalMedium }) =>
      orientationMedium === ORIENTATION.HORIZONTAL && widthHorizontalMedium
        ? `${widthHorizontalMedium}px`
        : undefined};
    height: ${({ orientationMedium, heightHorizontalMedium }) =>
      orientationMedium !== ORIENTATION.VERTICAL && heightHorizontalMedium
        ? `${heightHorizontalMedium}px`
        : undefined};
  }

  ${media.large} {
    padding-top: ${({ aspectRatioAsAPercentage, orientationLarge }) =>
      orientationLarge !== ORIENTATION.HORIZONTAL
        ? aspectRatioAsAPercentage
        : 0};
    width: ${({ orientationLarge, widthHorizontalLarge }) =>
      orientationLarge === ORIENTATION.HORIZONTAL && widthHorizontalLarge
        ? `${widthHorizontalLarge}px`
        : undefined};
    height: ${({ orientationLarge, heightHorizontalLarge }) =>
      orientationLarge !== ORIENTATION.VERTICAL && heightHorizontalLarge
        ? `${heightHorizontalLarge}px`
        : undefined};
  }
`;

export const SelectionControlStyledCheckbox = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.spacing.M16};
`;

export const Container = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadow.CONTAINER};
  overflow: hidden;
`;
