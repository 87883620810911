import type { ReactNode } from 'react';
import styled from 'styled-components';

import { Spacing, spacing } from 'helpers/genericStyles';

interface IHeadingProps extends Spacing {
  children: ReactNode;
}

const Container = styled.div`
  ${spacing};
  grid-column: 1 / 3;
`;

const Span = (props: IHeadingProps) => {
  const { children, ...spacing } = props;
  return <Container {...spacing}>{children}</Container>;
};

export { Span };
