import type { ReactNode } from 'react';
import styled from 'styled-components';

import { Spacing, spacing } from 'helpers/genericStyles';

interface IHeadingProps extends Spacing {
  children: ReactNode;
}

const Container = styled.h2`
  ${spacing};
  ${({ theme }) => theme.fontSize.L18};
  grid-column: 1 / 3;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
`;

const Heading = (props: IHeadingProps) => {
  const { children, ...spacing } = props;
  return <Container {...spacing}>{children}</Container>;
};

export { Heading };
